import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { baseurl, uploadImage } from "../constants";
import useLoader from "../Components/loader/useLoader";
import Switch from "@mui/material/Switch";

export default function Home() {
  const [data, setData] = useState();
  const [loader, showLoader, hideLoader] = useLoader();
  const [pagination, setPagination] = useState({});

  //** get data */
  const getData = async () => {
    showLoader();
    try {
      const response = await fetch(
        baseurl + "dashboard/home?userId=" + localStorage.getItem("id"),
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      const json = await response.json();
      console.log(json);
      hideLoader();
      setData(json);
      return json;
    } catch (error) {
      console.error(error);
    }
  };

  const rowIndex = (index) => {
    return (pagination["pageNo"] - 1) * 8 + (index + 1);
  };

  //** update provider */

  const updateProvider = async (providerId, status) => {
    showLoader();
    try {
      var formdata = new FormData();
      formdata.append("providerId", providerId);
      formdata.append("status", status);

      const response = await fetch(baseurl + "dashboard/update-provider", {
        body: formdata,
        method: "POST",

        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      console.log(json);
      getData();
      hideLoader();
      // setData(json);
      return json;
    } catch (error) {
      hideLoader();
      console.error(error + "errrrrrrrr");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loader}
      {data && (
        <>
          <div class="row row-cols-1 text-white row-cols-lg-2 row-cols-xl-4">
            <div class="col">
              <div class="card rounded-4  bg-primary">
                <div class="card-body ">
                  <div class="d-flex align-items-center">
                    <div class="">
                      <p class="mb-1">المزودين</p>
                      <h4 class="mb-0">{data.providers}</h4>
                      <p class="mb-0 mt-2 font-13">
                        <i class="bi bi-arrow-up"></i>
                        <span>عدد المزودين : {data.providers}</span>
                      </p>
                    </div>
                    <div class="ms-auto widget-icon bg-white text-primary">
                      <i class="bi bi-grid-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card bg-success rounded-4">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="">
                      <p class="mb-1">المنتجات</p>
                      <h4 class="mb-0">{data.products}</h4>
                      <p class="mb-0 mt-2 font-13">
                        <i class="bi bi-arrow-up"></i>
                        <span>عدد المنتجات : {data.products}</span>
                      </p>
                    </div>
                    <div class="ms-auto widget-icon bg-white text-success">
                      <i class="bi bi-basket2-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card bg-orange rounded-4">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="">
                      <p class="mb-1">الطلبات</p>
                      <h4 class="mb-0">{data.orders}</h4>
                      <p class="mb-0 mt-2 font-13">
                        <i class="bi bi-arrow-up"></i>
                        <span>عدد الطلبات : {data.orders} </span>
                      </p>
                    </div>
                    <div class="ms-auto widget-icon bg-white text-orange">
                      <i class="bi bi-award-fill"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card bg-secondary rounded-4">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="">
                      <p class="mb-1">العملاء</p>

                      <h4 class="mb-0">{data.users}</h4>
                      <p class="mb-0 mt-2 font-13">
                        <i class="bi bi-arrow-up"></i>
                        <span>عدد العملاء : {data.users}</span>
                      </p>
                    </div>
                    <div class="ms-auto widget-icon bg-white text-secondary">
                      <i class="bx bxs-package"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              <div className="row p-3 m-1 ">
                {/* <div class="col-xl-6 col-lg-6   mb-4">
  <div className="chart-box">
    <h3 className="chart-header">  المتاجر</h3>
    <ChartView data={data} />
  </div>
</div> */}

                <div className="home-footer">
                  <div>
                    <h3 className="home-order-header">
                      {" "}
                      المزودون المنضمون حديثا
                    </h3>
                    {/* //* providers */}
                    <div>
                      <table
                        id="DataTable"
                        className="table table-striped table-bordered dataTable"
                        role="grid"
                        aria-describedby="example2_info"
                      >
                        <thead>
                          <tr role="row">
                            <th
                              className="sorting_asc text-center align-middle"
                              tabIndex={0}
                              aria-controls="example2"
                              rowSpan={1}
                              colSpan={1}
                              aria-sort="ascending"
                              aria-label="Name: activate to sort column descending"
                              style={{ width: "30.828px" }}
                            >
                              <span className="mx-3">م</span>
                            </th>
                            <th
                              className="sorting_asc text-center align-middle"
                              tabIndex={0}
                              aria-controls="example2"
                              rowSpan={1}
                              colSpan={1}
                              aria-sort="ascending"
                              aria-label="Name: activate to sort column descending"
                              style={{ width: "158.828px" }}
                            >
                              {" "}
                              اسم المزود{" "}
                            </th>

                            {/* <th
                              className="sorting"
                              tabIndex={0}
                              aria-controls="example2"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Position: activate to sort column ascending"
                              style={{ width: "320.891px" }}
                            >
                              العنوان{" "}
                            </th> */}
                            <th
                              className="sorting text-center align-middle"
                              tabIndex={0}
                              aria-controls="example2"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Position: activate to sort column ascending"
                              style={{ width: "80.891px" }}
                            >
                              صورة{" "}
                            </th>
                            <th
                              className="sorting text-center align-middle"
                              tabIndex={0}
                              aria-controls="example2"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Position: activate to sort column ascending"
                              style={{ width: "100.891px" }}
                            >
                              تاريخ الإضافة
                            </th>
                            <th
                              className="sorting text-center align-middle"
                              tabIndex={0}
                              aria-controls="example2"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Position: activate to sort column ascending"
                              style={{ width: "70.891px" }}
                            >
                              الحالة
                            </th>
                            <th
                              className="sorting text-center align-middle"
                              tabIndex={0}
                              aria-controls="example2"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Office: activate to sort column ascending"
                              style={{ width: "60.312px" }}
                            >
                              نشر / ايقاف
                            </th>
                            <th
                              className="sorting text-center align-middle"
                              tabIndex={0}
                              aria-controls="example2"
                              rowSpan={1}
                              colSpan={1}
                              aria-label="Position: activate to sort column ascending"
                              style={{ width: "100.891px" }}
                            >
                              تفاصيل
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.lastProviders.map((item, index) => (
                              <tr role="row" className="odd">
                                {/* <span className="mx-3">{rowIndex(index)}</span> */}
                                <td className="text-center align-middle">
                                  {item.provider.id}
                                </td>
                                <td className="text-center align-middle">
                                  {item.provider.title}
                                </td>

                                {/* <td>{item.addressName}</td> */}
                                <td className="text-center align-middle">
                                  <div className=" gap-3 cursor-pointer">
                                    <a
                                      href={
                                        baseurl +
                                        "/images/" +
                                        item.provider.logoCompany
                                      }
                                    >
                                      <img
                                        className="rounded-circle"
                                        src={
                                          baseurl +
                                          "/images/" +
                                          item.provider.logoCompany
                                        }
                                        // className="rounded-circle"
                                        width={80}
                                        height={80}
                                        alt=""
                                      />
                                    </a>
                                  </div>
                                </td>
                                <td className="text-center align-middle">
                                  {item.provider.createdAt.split("T")[0]}
                                </td>
                                <td className="text-center align-middle">
                                  {item.provider.status === 1
                                    ? "مفعل"
                                    : "غير مفعل"}
                                </td>

                                <td className="text-center align-middle">
                                  {/* <Link state={{
                          row: item,
                        }} to={"/admin/Product/detail"}>
                          <a type="button" asp-action="Details" className="btn btn-primary m-1 text-center"><i className="far fa-eye" /></a>
                        </Link>
*/}
                                  <div
                                    onClick={() =>
                                      updateProvider(
                                        item.provider.id,
                                        item.provider.status === 0 ? 1 : 0
                                      )
                                    }
                                    // state={{
                                    //   row: item,
                                    // }}
                                    // to={"/admin/Product/edit"}
                                  >
                                    {/* <a
                                      asp-action="Edit"
                                      asp-route-id="@item.Id"
                                      className="btn btn-success m-1 text-center"
                                    >
                                      <i className="fas fa-edit" />
                                    </a> */}
                                    <Switch
                                      checked={
                                        item.provider.status === 1
                                          ? true
                                          : false
                                      }
                                      inputProps={{ "aria-label": "Switch A" }}
                                    />
                                  </div>

                                  {/* <Link
                                    state={{
                                      row: item,
                                    }}
                                    to={"/admin/Product/delete"}
                                  >
                                    <a
                                      asp-action="Delete"
                                      asp-route-id="@item.Id"
                                      className="btn btn-danger m-1 text-center"
                                    >
                                      <i className="far fa-trash-alt" />
                                    </a>
                                  </Link> */}
                                </td>

                                {/*  details */}
                                <td className="text-center align-middle">
                                  {" "}
                                  <Link
                                    state={{
                                      row: item,
                                    }}
                                    to={"/admin/fields/detail"}
                                  >
                                    <a
                                      type="button"
                                      asp-action="Details"
                                      className="btn btn-primary m-1 text-center"
                                    >
                                      <i className="far fa-eye" />
                                    </a>
                                  </Link>
                                  <Link
                                    state={{
                                      row: item,
                                    }}
                                    to={"/admin/field/edit"}
                                  >
                                    <a
                                      asp-action="Edit"
                                      asp-route-id="@item.Id"
                                      className="btn btn-success m-1 text-center"
                                    >
                                      <i className="fas fa-edit" />
                                    </a>
                                  </Link>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                    {/* <PieChartView data={data.lastProviders} /> */}
                  </div>
                </div>
                {/* 
<div class="col-xl-6 col-lg-6   mb-4">
  <div className="chart-box ">
    <h3 className="chart-header"> المستخدمين</h3>
    <LineChart data={data}/>
  </div>

</div>
<div class="col-xl-6 col-lg-6   mb-4">
  <div className="chart-box">
    <h3 className="chart-header"> العروض الفعالة</h3>
    <OrderStatusChart data={data} />
  </div>
</div> */}

                {/* 

<div class="col-xl-6 col-lg-6   mb-4">
  <div className="chart-box">
    <h3 className="chart-header">  المستخدمين</h3>
    <LineChart data={data} />
  </div>
</div>

<div class="col-xl-6 col-lg-6   mb-4">
  <div className="chart-box">
    <h3 className="chart-header">  نسبة ال للمدينة </h3>
    <DriversRateChart data={data} />
  </div>
</div> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
