import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl, OnInput, onInputInvalid, successNotify, uploadImage } from "../../constants";


export default function CreateMarket() {

  var [image,setImage] = useState([])

  const handleNewImage = event => {
    image = event.target.files
  }
  const [cities, setCities] = useState()
  const [fields, setFields] = useState()
  const [loader, showLoader, hideLoader] = useLoader(); 

  const getFields = async () => {
    try {
      const response = await fetch(baseurl + "field/all", {

        method: "GET",

      });
      const json = await response.json();
      console.log(json);
      setFields(json)
      return json;
    } catch (error) {
      console.error(error);
    }
  }

  const getCities = async () => {
    try {
      const response = await fetch(baseurl + "city/all", {

        method: "GET",

      });
      const json = await response.json();
      console.log("cities");
      console.log(json);
      setCities(json)
      return json;
    } catch (error) {
      console.error(error);
    }
  }

  const uploadImages=async (image)=>{
    const arr = []
    for (const element of image) {
       const url = await uploadImage(element)
       arr.push(url)
     }
     console.log(arr.join(","));
     return arr.join(",")
  }

  const navigateTo = useNavigate()
  const postForm = async (event) => {
    showLoader()
    event.preventDefault();
    const formdata = new FormData(event.target);
    let uploadedImage = await uploadImages(image)
    console.log(uploadedImage);
    formdata.append("image", uploadedImage)
    const response = await fetch(baseurl + "Market/add", {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + localStorage.getItem("token")
      },
      body: formdata
    });
    const json = await response.json();
    hideLoader()
    successNotify()
    console.log(json);
    navigateTo("/admin/Markets");

  };

  const initData = async () => {
        await getCities()
        await getFields()
    
}

  useEffect(() => {
       
        initData()
    
}, []);

  return <div>
    {loader}
    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
      <div className="breadcrumb-title pe-3">المتاجر</div>
      <div className="ps-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 p-0">
            <li className="breadcrumb-item">
              <a href="javascript:;">
                <i className="bx bx-home-alt" />
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">إضافة متجر</li>
          </ol>
        </nav>
      </div>
    </div>
    {/*end breadcrumb*/}
    <hr />
    <div className="col-xl-12 mx-auto">
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <h6 className="mb-0 text-uppercase">معلومات المتجر</h6>
            <hr />
            <form  onSubmit={postForm} encType="multipart/form-data" >
              <div asp-validation-summary="ModelOnly" className="text-danger" />
              <div className="row g-3">
                <div className="col-6">
                  <label className="form-label">اختر الفئة </label>
                  {fields && (
                    <select

                    onInvalid={onInputInvalid }
                        onInput={OnInput} required 
                      name="fieldId"
                      id="school-id"
                      className="form-select"
                    >

<option  value={""}>
                          اختر 
                        </option>
                      {fields.map(row => (
                        <option key={row.id} value={row.id}>
                          {row.name}
                        </option>
                      ))}
                    </select>
                  )}

                  <span asp-validation-for="FieldId" className="text-danger" />
                </div>
                <div className="col-6">
                  <label className="form-label">اختر المنطقة </label>
                  {cities && (
                    <select
                      name="city"
                      

                      onInvalid={onInputInvalid }
                        onInput={OnInput} required 
                      id="school-id"
                      className="form-select"
                    >
                      <option  value={""}>
                          اختر 
                        </option>
                      {cities.map((row) => (
                        <option key={row.id} value={row.name}>
                          {row.name}
                        </option>
                      ))}
                    </select>
                  )}                  <span asp-validation-for="FieldId" className="text-danger" />
                </div>
                <div className="col-6">
                  <label className="form-label">الإسم باللغة العربية </label>
                  <input  onInvalid={onInputInvalid }
                        onInput={OnInput} required autoComplete="off" name="Name" type="text" className="form-control" />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>
                <div className="col-6">
                  <label className="form-label">الإسم باللغة الإنجليزية </label>
                  <input  onInvalid={onInputInvalid }
                        onInput={OnInput} required autoComplete="off" name="NameEn" type="text" className="form-control" />
                  <span asp-validation-for="NameEn" className="text-danger" />
                </div>
                <div className="col-6">
                  <label className="form-label">وصف  عن المتجر </label>
                  <input  onInvalid={onInputInvalid }
                        onInput={OnInput} required autoComplete="off" name="Info" type="text" className="form-control" />
                  <span asp-validation-for="Info" className="text-danger" />
                </div>
                <div className="col-6">
                  <label className="form-label">رقم التواصل </label>
                  <input autoComplete="off" name="Phone" type="text" className="form-control" />
                </div>
                <div className="col-6">
                  <label className="form-label">البريد الإلكتروني </label>
                  <input   autoComplete="off" name="Email" type="text" className="form-control" />
                </div>
                <div className="col-6">
                  <label className="form-label">الموقع الإلكتروني </label>
                  <input autoComplete="off" name="Website" type="text" className="form-control" />
                </div>
                <div className="col-6">
                  <label className="form-label">العنوان</label>
                  <input    onInvalid={onInputInvalid }
                        onInput={OnInput} required autoComplete="off" name="Address" type="text" className="form-control" />
                  <span asp-validation-for="Address" className="text-danger" />
                </div>
                <div className="col-6">
                  <label className="form-label">Latitude</label>
                  <input  autoComplete="off" name="Lat" type="text" className="form-control" />
                </div>
                <div className="col-6">
                  <label className="form-label">Longitude</label>
                  <input   autoComplete="off" name="Lng" type="text" className="form-control" />
                </div>
                <div className="col-6">
                  <label htmlFor="ImageFile" className="form-label">صور للمتجر <span className="text-success">حجم الصورة (  1200x600 )</span></label>
                  <input multiple="multiple"  onInvalid={onInputInvalid }
                        onInput={OnInput} onChange={handleNewImage} required name="ImageFile" className="form-control form-control-lg" id="formFileLg" type="file" />
                  <span asp-validation-for="ImageFile" className="text-danger" />
                </div>
                <div className="col-12">
                  <div className="d-grid">
                    <input  onInvalid={onInputInvalid }
                        onInput={OnInput} required type="submit" value="حفظ" className="btn btn-primary" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

}

