import "./App.css";
import Dashboard from "./Components/Dashboard";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import CreateField from "./Pages/Providers/Create";
import Fields from "./Pages/Providers/Index";
import FieldDetail from "./Pages/Providers/Detail";
import EditField from "./Pages/Providers/Edit";
import FieldDelete from "./Pages/Providers/Delete";

import CreateProduct from "./Pages/Products/Create";
import EditProduct from "./Pages/Products/Edit";
import ProductDelete from "./Pages/Products/Delete";
import Products from "./Pages/Products/Index";
import ProductDetail from "./Pages/Products/Detail";

import CreateMarket from "./Pages/Markets/Create";
import EditMarket from "./Pages/Markets/Edit";
import MarketDelete from "./Pages/Markets/Delete";
import Markets from "./Pages/Markets/Index";
import MarketDetail from "./Pages/Markets/Detail";

import CreateOffer from "./Pages/Orders/Create";
import EditOffer from "./Pages/Orders/Edit";
import OfferDelete from "./Pages/Orders/Delete";

import OfferDetail from "./Pages/Orders/Detail";

import CityDetail from "./Pages/Categories/Detail";

import Users from "./Pages/Users/Index";
import Notifs from "./Pages/Notifs/Index";
import CreateNotif from "./Pages/Notifs/Create";
import GroupedReport from "./Pages/Reports/GroupedReport";
import Orders from "./Pages/Orders/Index";
import Wallets from "./Pages/wallets/Index";
import CreateCategory from "./Pages/Categories/Create";
import Categories from "./Pages/Categories/Index";
import EditCategory from "./Pages/Categories/Edit";
import DeleteCategory from "./Pages/Categories/Delete";
import UserDetail from "./Pages/Users/Details";
import EditUser from "./Pages/Users/Edit";
import Settings from "./Pages/Settings/Index";
import EditSetting from "./Pages/Settings/Edit";
import OrdersByProvider from "./Pages/Providers/OrdersProvider";
import DeleteAccounnt from "./Pages/DeleteAccount/Index";
import OrdersApp from "./Pages/OrdersApp/Index";
import ProductsByProvider from "./Pages/Providers/ProductsProvider";
import OrderDetails from "./Pages/OrdersApp/DetailsOrder";
import Alerts from './Pages/Alerts/Index';
import CreateAlert from './Pages/Alerts/Create';
import DeleteAlert from './Pages/Alerts/Delete';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="login" element={<Login />} />
          <Route path="admin" element={<Dashboard />}>
            <Route path="" element={<Home />} />
            <Route path="home" element={<Home />} />

            {/* Fields */}

            <Route path="field/add" element={<CreateField />} />
            <Route path="field/edit" element={<EditField />} />
            <Route path="field/delete" element={<FieldDelete />} />
            <Route path="fields" element={<Fields />} />
            <Route path="fields/detail" element={<FieldDetail />} />
            <Route
              path="fields/ordersbyprovider"
              element={<OrdersByProvider />}
            />
            <Route
              path="fields/productsbyprovider"
              element={<ProductsByProvider />}
            />



            {/* Markets */}
            <Route path="market/add" element={<CreateMarket />} />
            <Route path="market/edit" element={<EditMarket />} />
            <Route path="market/delete" element={<MarketDelete />} />
            <Route path="markets" element={<Markets />} />
            <Route path="market/detail" element={<MarketDetail />} />

            {/* Products */}
            <Route path="Provider/add" element={<CreateProduct />} />
            <Route path="Product/edit" element={<EditProduct />} />
            <Route path="Product/delete" element={<ProductDelete />} />
            <Route path="Products" element={<Products />} />
            <Route path="Product/detail" element={<ProductDetail />} />

            {/* Orderrs */}
            <Route path="orders-all" element={<OrdersApp />} />
            <Route path="order/detail" element={<OrderDetails />} />

            {/* alerts */}
            <Route path="alerts" element={<Alerts />} />
            <Route path="alert/add" element={<CreateAlert />} />
            <Route path="alert/delete" element={<DeleteAlert />} />
            {/* Offers */}
            <Route path="Offer/add" element={<CreateOffer />} />
            <Route path="Offer/edit" element={<EditOffer />} />
            <Route path="Offer/delete" element={<OfferDelete />} />
            <Route path="orders" element={<Orders />} />
            <Route path="wallet" element={<Wallets />} />
            <Route path="Offer/detail" element={<OfferDetail />} />

            {/* delete account */}
            <Route path="deleteAccount" element={<DeleteAccounnt />} />
            {/* Cities */}
            <Route path="Category/add" element={<CreateCategory />} />
            <Route path="Category/edit" element={<EditCategory />} />
            <Route path="Category/delete" element={<DeleteCategory />} />
            <Route path="Categories" element={<Categories />} />
            <Route path="City/detail" element={<CityDetail />} />

            {/* Sittings */}
            {/* <Route path="Category/add" element={<CreateCategory/>} />
          <Route path="Category/edit" element={<EditCategory />} />
          <Route path="Category/delete" element={<DeleteCategory />} /> */}
            <Route path="Setting/edit" element={<EditSetting />} />
            <Route path="Sittings" element={<Settings />} />
            {/* <Route path="City/detail" element={<CityDetail />} /> */}

            {/* Users */}
            <Route path="users" element={<Users />} />
            <Route path="user/detail" element={<UserDetail />} />
            <Route path="user/edit" element={<EditUser />} />

            {/* Notifs */}
            <Route path="notifs" element={<Notifs />} />
            <Route path="notif/add" element={<CreateNotif />} />

            {/* Notifs */}
            <Route path="/admin/grouped-report" element={<GroupedReport />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
