import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl, OnInput, onInputInvalid, successNotify, uploadImage } from "../../constants";


export default function CreateProduct() {

  var [image, setImage] = useState()

  const handleNewImage = event => {
    image = event.target.files[0]
  }
  const [cities, setCities] = useState()
  const [markets, setmarkets] = useState()
  const getmarkets = async () => {
    try {
      const response = await fetch(baseurl + "market/all", {

        method: "GET",

      });
      const json = await response.json();
      console.log(json);
      setmarkets(json)
      return json;
    } catch (error) {
      console.error(error);
    }
  }

 


  const navigateTo = useNavigate()
  const [loader, showLoader, hideLoader] = useLoader(); 

  const postForm = async (event) => {
    showLoader()
    event.preventDefault();
    const formdata = new FormData(event.target);

    let uploadedImage = await uploadImage(image)
    console.log(uploadedImage);
    formdata.append("image", uploadedImage)
    const response = await fetch(baseurl + "Product/add", {
      method: "POST",
      headers: {

        "Authorization": "Bearer " + localStorage.getItem("token")
      },
      body: formdata
    });
    const json = await response.json();
    hideLoader()
    console.log(json);
    successNotify()

    navigateTo("/admin/Products");

  };

  const initData = async () => {
    await getmarkets()

  }

  useEffect(() => {

    initData()

  }, []);

  return <div>
    {loader}
    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
      <div className="breadcrumb-title pe-3">العروض</div>
      <div className="ps-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 p-0">
            <li className="breadcrumb-item">
              <a href="javascript:;">
                <i className="bx bx-home-alt" />
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">إضافة عرض</li>
          </ol>
        </nav>
      </div>
    </div>
    {/*end breadcrumb*/}
    <hr />
    <div className="col-xl-12 mx-auto">
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <h6 className="mb-0 text-uppercase">معلومات العرض</h6>
            <hr />
            <form onSubmit={postForm} encType="multipart/form-data" >
              <div asp-validation-summary="ModelOnly" className="text-danger" />
              <div className="row g-3">
                <div className="col-6">
                  <label className="form-label">اختر المتجر </label>
                  {markets && (
                    <select

                      onInvalid={onInputInvalid}
                      onInput={OnInput} required
                      name="marketId"
                      id="school-id"
                      className="form-select"
                    >

                      <option value={""}>
                        اختر
                      </option>
                      {markets.map(row => (
                        <option key={row.id} value={row.id}>
                          {row.name}
                        </option>
                      ))}
                    </select>
                  )}

                  <span asp-validation-for="marketId" className="text-danger" />
                </div>
             
                <div className="col-6">
                  <label className="form-label">الإسم باللغة العربية </label>
                  <input onInvalid={onInputInvalid}
                    onInput={OnInput} required autoComplete="off" name="Name" type="text" className="form-control" />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>
                <div className="col-6">
                  <label className="form-label">الإسم باللغة الإنجليزية </label>
                  <input onInvalid={onInputInvalid}
                    onInput={OnInput} required autoComplete="off" name="NameEn" type="text" className="form-control" />
                  <span asp-validation-for="NameEn" className="text-danger" />
                </div>
                <div className="col-6">
                  <label className="form-label">الوصف باللغة العربية </label>
                  <input onInvalid={onInputInvalid}
                    onInput={OnInput} required autoComplete="off" name="Description" type="text" className="form-control" />
                  <span asp-validation-for="Description" className="text-danger" />
                </div>
                <div className="col-6">
                  <label className="form-label">الوصف باللغة الإنجليزية </label>
                  <input autoComplete="off" name="DescriptionEn" type="text" className="form-control" />
                </div>
                <div className="col-6">
                  <label className="form-label">السعر </label>
                  <input autoComplete="off" name="Price" type="text" className="form-control" />
                </div>
                <div className="col-6">
                  <label className="form-label">الخصم </label>
                  <input autoComplete="off" name="Discount" type="text" className="form-control" />
                </div>
                <div className="col-6">
                  <label className="form-label"> الحالة </label>
                  {markets && (
                    <select

                      onInvalid={onInputInvalid}
                      onInput={OnInput} required
                      name="status"
                      id="school-id"
                      className="form-select"
                    >

                      <option value={""}>
                        اختر
                      </option>
                      <option value={1}>
                        مفعل
                      </option>
                      <option value={0}>
                        غير مفعل
                      </option>
                      
                    </select>
                  )}

                  <span asp-validation-for="marketId" className="text-danger" />
                </div>
                <div className="col-6">
                  <label htmlFor="ImageFile" className="form-label">صورة للعرض <span className="text-success">حجم الصورة (  1200x600 )</span></label>
                  <input  onInvalid={onInputInvalid }
                        onInput={OnInput} onChange={handleNewImage} required name="ImageFile" className="form-control form-control-lg" id="formFileLg" type="file" />
                  <span asp-validation-for="ImageFile" className="text-danger" />
                </div>
                <div className="col-12">
                  <div className="d-grid">
                    <input onInvalid={onInputInvalid}
                      onInput={OnInput} required type="submit" value="حفظ" className="btn btn-primary" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

}