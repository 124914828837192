import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import validator from "validator";
import axios from "axios";
import { baseurl, successNotify, errorNotifyMessage } from "../../constants";

export default function EditField() {
  var [title, setTitle] = useState();
  var [email, setEmail] = useState();
  var [about, setAbout] = useState();
  var [admin, setAdmin] = useState();
  var [area, setArea] = useState();
  var [iban, setIban] = useState();


  var [addressName, setAddressName] = useState();
  var [lat, setLat] = useState();
  var [lng, setLng] = useState();

  var [nameBunk, setNameBunk] = useState();
  var [logoCompany, setLogoCompany] = useState("");
  var [imagePassport, setImagePassport] = useState([]);
  const [loader, showLoader, hideLoader] = useLoader();

  const navigateTo = useNavigate();

  const handleNewImageLogo = async (event) => {
    logoCompany = event.target.files[0];
    // setImageLogo(image);
  };

  ////*** upload image */
  const uploadImageHatli = async (file, type) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const resp = await axios
        .post(baseurl + "image/upload/image", formData, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((e) => {
          console.log(e.data);
          if (type === 0) {
            setLogoCompany(e.data);
          } else {
            setImagePassport(e.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
  const handleNewImagePassPort = async (event) => {
    imagePassport = event.target.files[0];
    // setImagePassport(event.target.files[0]);
  };
  const location = useLocation();
  const row = location.state.row;
  useEffect(() => {
    if (location.state != null) {
      setImagePassport(location.state.row.provider.imagePassport);
      setLogoCompany(location.state.row.provider.logoCompany);
    }
  }, []);

  const postForm = async () => {
    console.log(logoCompany);
    showLoader();
    //  if(handleValidation()==false)return;
    try {
      const formdata = new FormData();
      formdata.append("id", row.provider.id);
      formdata.append("title", title ?? row.provider.title);
      formdata.append("about", about ?? row.provider.about);
      formdata.append("email", email ?? row.provider.email);
      formdata.append("categoryId", row.provider.categoryId);
      formdata.append(
        "nameAdministratorCompany",
        admin ?? row.provider.nameAdministratorCompany
      );
      formdata.append("nameBunk", nameBunk ?? row.provider.nameBunk);
      formdata.append("iBan", iban ?? row.provider.iBan);
      formdata.append("area", area ?? row.provider.area);
      formdata.append("lat", lat ?? row.provider.lat);
      formdata.append("lng", lng ?? row.provider.lng);
      formdata.append("AddressName", addressName ?? row.provider.addressName);
      formdata.append("LogoCompany", logoCompany);
      formdata.append("ImagePassport", imagePassport);

      const response = await fetch(baseurl + "provider/update-provider", {
        method: "POST",
        //   headers: {
        //     Authorization: "Bearer " + localStorage.getItem("token"),
        //   },
        body: formdata,
      });

      hideLoader();
      successNotify();

      const json = await response.json();
      console.log(json);
      navigateTo("/admin/fields");
    } catch (error) {
      hideLoader();
      console.error(error + "kjjjjjjj");
    }
  };

  return (
    <div>
      {loader}
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">المزودين</div>
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                تعدبل المزود
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/*end breadcrumb*/}
      <hr />
      <div className="col-xl-12 mx-auto">
        <div className="card">
          <div className="card-body">
            <div className="border p-3 rounded">
              <h6 className="mb-0 text-uppercase">معلومات المزود</h6>
              <hr />
              <div asp-validation-summary="ModelOnly" className="text-danger" />
              <div className="row g-3">
                {/* title */}
                <div className="col-6">
                  <label className="form-label">الإسم </label>
                  <input
                    onChange={(e) => (title = e.target.value)}
                    defaultValue={row.provider.title}
                    autoComplete="off"
                    asp-for="Title"
                    type="text"
                    className="form-control"
                  />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>
                {/* email */}
                <div className="col-6">
                  <label className="form-label"> ايميل الشركة </label>
                  <input
                    onChange={(e) => (email = e.target.value)}
                    defaultValue={row.provider.email}
                    autoComplete="off"
                    asp-for="Email"
                    type="text"
                    className="form-control"
                  />
                  <span asp-validation-for="NameEn" className="text-danger" />
                </div>
                {/* desc */}
                <div className="col-12">
                  <label className="form-label"> تفاصيل الشركة </label>
                  <textarea
                    wrap="soft"
                    rows="5"
                    cols="150"
                    onChange={(e) => (about = e.target.value)}
                    autoComplete="off"
                    type="text"
                    className="form-control"
                    defaultValue={row.provider.about}
                  />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>

                {/*admin */}
                <div className="col-6">
                  <label className="form-label">الشخص المسئول </label>
                  <input
                    onChange={(e) => (admin = e.target.value)}
                    defaultValue={row.provider.nameAdministratorCompany}
                    autoComplete="off"
                    asp-for="Admin"
                    type="text"
                    className="form-control"
                  />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>
                {/*area */}
                <div className="col-6">
                  <label className="form-label"> نطاق البيع </label>
                  <input
                    onChange={(e) => (area = e.target.value)}
                    defaultValue={row.provider.area}
                    autoComplete="off"
                    asp-for="Area"
                    maxLength="10"
                    type="number"
                    className="form-control"
                  />
                  <span asp-validation-for="NameEn" className="text-danger" />
                </div>

                {/*address */}
                <div className="col-6">
                  <label className="form-label"> اسم العنوان</label>
                  <input
                    onChange={(e) => (addressName = e.target.value)}
                    defaultValue={row.provider.addressName}
                    autoComplete="off"
                    asp-for="addressName"
                 
                    type="text"
                    className="form-control"
                  />
                  <span asp-validation-for="NameEn" className="text-danger" />
                </div>

                {/*lat */}
                <div className="col-6">
                  <label className="form-label"> خط الطول - Lat -</label>
                  <input
                    onChange={(e) => (lat = e.target.value)}
                    defaultValue={row.provider.lat}
                    autoComplete="off"
                    asp-for="lat"
               
                    type="text"
                    className="form-control"
                  />
                  <span asp-validation-for="NameEn" className="text-danger" />
                </div>
                {/*lng */}
                <div className="col-6">
                  <label className="form-label"> خط العرض - Lng -</label>
                  <input
                    onChange={(e) => (lng = e.target.value)}
                    defaultValue={row.provider.lng}
                    autoComplete="off"
                    asp-for="lat"
            
                    type="text"
                    className="form-control"
                  />
                  <span asp-validation-for="NameEn" className="text-danger" />
                </div>
                {/*name bunk */}
                <div className="col-6">
                  <label className="form-label"> اسم البنك </label>
                  <input
                    onChange={(e) => (nameBunk = e.target.value)}
                    defaultValue={row.provider.nameBunk}
                    autoComplete="off"
                    asp-for="NameBunk"
                    type="text"
                    className="form-control"
                  />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>
                {/*IBan */}
                <div className="col-6">
                  <label className="form-label"> الآيبان </label>
                  <input
                    onChange={(e) => (iban = e.target.value)}
                    defaultValue={row.provider.iBan}
                    autoComplete="off"
                    asp-for="IBane"
                    type="text"
                    className="form-control"
                  />
                  <span asp-validation-for="NameEn" className="text-danger" />
                </div>

                {/* LOGO IMAGE */}
                <div className="col-6">
                  <label htmlFor="ImageFile" className="form-label">
                    شعار الشركة{" "}
                    {/* <span className="text-success">حجم الصورة ( 260x260 )</span> */}
                  </label>
                  {/* <div className="image">
                    <img
                      src={baseurl + "images/" + imageLogo}
                      width={100}
                      height={100}
                      alt=""
                    />
                  </div> */}
                  <br />
                  <input
                    onChange={async (e) => {
                      uploadImageHatli(e.target.files[0], 0);
                    }}
                    asp-for="ImageFile"
                    className="form-control form-control-lg"
                    id="formFileLg"
                    type="file"
                  />
                  <span
                    asp-validation-for="ImageFile"
                    className="text-danger"
                  />
                </div>
                {/* PASS IMAGE */}
                <div className="col-6">
                  <label htmlFor="ImageFile" className="form-label">
                    صورة الاثبات{" "}
                    {/* <span className="text-success">حجم الصورة ( 260x260 )</span> */}
                  </label>
                  {/* <div className="image">
                    <img
                      src={baseurl + "images/" + row.provider.imagePassport}
                      width={100}
                      height={100}
                      alt=""
                    />
                  </div> */}
                  <br />
                  <input
                    onChange={async (e) => {
                      uploadImageHatli(e.target.files[0], 1);
                    }}
                    asp-for="ImageFile"
                    className="form-control form-control-lg"
                    id="formFileLg"
                    type="file"
                  />
                  <span
                    asp-validation-for="ImageFile"
                    className="text-danger"
                  />
                </div>
                <div onClick={postForm} className="col-12">
                  <div className="d-grid">
                    <input
                      type="submit"
                      value="حفظ"
                      className="btn btn-primary"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
