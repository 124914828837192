import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { baseurl, OnInput, onInputInvalid, successNotify, uploadImage } from "../../constants";
import useLoader from "../../Components/loader/useLoader";


export default function EditOffer() {

  var [image, setImage] = useState()
  const location = useLocation();
  const row = location.state.row;
  const handleNewImage = event => {
    image = event.target.files[0]
  }
  const [cities, setCities] = useState()
  const [Products, setProducts] = useState()
  const getProducts = async () => {
    try {
      const response = await fetch(baseurl + "Product/all", {

        method: "GET",

      });
      const json = await response.json();
      console.log(json);
      setProducts(json)
      return json;
    } catch (error) {
      console.error(error);
    }
  }




  const navigateTo = useNavigate()
  const [loader, showLoader, hideLoader] = useLoader(); 

  const postForm = async (event) => {
    showLoader()
    event.preventDefault();
    const formdata = new FormData(event.target);
    formdata.append("id", row.id)

    if(image==null){
      formdata.append("image", row.image)
  }else{
      let uploadedImage = await uploadImage(image)
      console.log(uploadedImage);
      formdata.append("image", uploadedImage)
  }
    const response = await fetch(baseurl + "Offer/update", {
      method: "POST",
      headers: {

        "Authorization": "Bearer " + localStorage.getItem("token")
      },
      body: formdata
    });
    const json = await response.json();
    hideLoader()
    successNotify()

    console.log(json);
    navigateTo("/admin/Offers");

  };

  const initData = async () => {
    await getProducts()

  }

  useEffect(() => {

    initData()

  }, []);

  return <div>
  <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
    <div className="breadcrumb-title pe-3">الإعلانات</div>
    <div className="ps-3">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb mb-0 p-0">
          <li className="breadcrumb-item">
            <a href="javascript:;">
              <i className="bx bx-home-alt" />
            </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">إضافة إعلان</li>
        </ol>
      </nav>
    </div>
  </div>
  {/*end breadcrumb*/}
  <hr />
  <div className="col-xl-12 mx-auto">
    <div className="card">
      <div className="card-body">
        <div className="border p-3 rounded">
          <h6 className="mb-0 text-uppercase">معلومات الإعلان</h6>
          <hr />
          <form onSubmit={postForm} encType="multipart/form-data" >
            <div asp-validation-summary="ModelOnly" className="text-danger" />
            <div className="row g-3">
              <div className="col-6">
                <label className="form-label">اختر العرض </label>
                {Products && (
                  <select

                    onInvalid={onInputInvalid}
                    
                    onInput={OnInput} required
                    name="ProductId"
                    id="school-id"
                    className="form-select"
                  >

                    <option value={""}>
                      اختر
                    </option>
                    {Products.map(item => (
                      <option selected={row.productId==item.id} key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                )}

                <span asp-validation-for="ProductId" className="text-danger" />
              </div>
           
              <div className="col-6">
                <label className="form-label">الإسم  </label>
                <input defaultValue={row.name} onInvalid={onInputInvalid}
                  onInput={OnInput} required autoComplete="off" name="Name" type="text" className="form-control" />
                <span asp-validation-for="Name" className="text-danger" />
              </div>
             
           
              <div className="col-6">
                <label className="form-label"> الحالة </label>
                { (
                  <select

                  onInvalid={onInputInvalid}
                  onInput={OnInput} required
                  name="status"
                  id="school-id"
                  className="form-select"
                >

                  <option value={""}>
                    اختر
                  </option>
                  <option selected={row.status==1} value={1}>
                    مفعل
                  </option>
                  <option selected={row.status==0} value={0}>
                    غير مفعل
                  </option>
                  
                </select>
                )}

                <span asp-validation-for="ProductId" className="text-danger" />
              </div>
              <div className="col-6">
                <label htmlFor="ImageFile" className="form-label">صورة للإعلان <span className="text-success">حجم الصورة (  1200x600 )</span></label>
                <input name="ImageFile" className="form-control form-control-lg" id="formFileLg" type="file" />
                <span asp-validation-for="ImageFile" className="text-danger" />
              </div>
              <div className="col-12">
                <div className="d-grid">
                  <input onInvalid={onInputInvalid}
                    onInput={OnInput} required type="submit" value="حفظ" className="btn btn-primary" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

}