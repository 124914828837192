import { useNavigate } from "react-router-dom";

export default function BackButton({onClick}) {
  const navigate = useNavigate();
  const goBack = () => {
    console.log("goBack");
    navigate(-1)
  }
  return (
    <div className="col-auto">
      <button
        onClick={goBack}
        className="btn btn  btn-danger btn-sm mx-20"
        type="submit"
      >
        رجوع
      </button>
    </div>
  );
}
