import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl, OnInput, onInputInvalid, successNotify, uploadImage } from "../../constants";
import axios from "axios";


export default function EditProduct() {



  const location = useLocation();
  const row = location.state.row;
  // const handleNewImage = event => {
  //   image = event.target.files[0]
  // }
  const [images, setImages] = useState([])
  const [name, setName] = useState()
  const [price, setPrice] = useState()
  const [desc, setDesc] = useState()
  const [discount, setDiscount] = useState()
  const [files, setFiles] = useState(null);
  const arr = [];
  const uploadImages = async (image) => {
    arr.slice();
    for (const element of files) {
      try {
        const formData = new FormData();
        formData.append("file", element);

        await axios
          .post(baseurl + "image/upload/image", formData, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then((e) => {
            console.log(e.data + "img");
            arr.push(e.data);

            setImages(arr.join("#"));
          });
      } catch (e) {
        console.log(e);
      }
    }
    console.log(arr.join("#"));
    return arr.join("#");
  };

  const handleSliderImages = (e) => {
    if (e.target.files) {
      // files.forEach((img) => URL.revokeObjectURL(img.preview));
      setFiles([...e.target.files]);
    }
    console.log("Update slider images", files);
  };
  const handleRemove = (id) => {
    const fileItem = files.filter((person) => person !== id);

    setFiles(fileItem);
  };
  const navigateTo = useNavigate()
  const [loader, showLoader, hideLoader] = useLoader();

  const postForm = async (event) => {

    showLoader()
    event.preventDefault();
    const formdata = new FormData(event.target);
    if (files == null) {
      console.log("not");
      formdata.append("images", row.product.images);
    } else {
      await uploadImages();
      formdata.append("images", arr.join("#"));
    }
    console.log(+ "nammmm");



    try {
      formdata.append("name", name);
      // formdata.append("images", "a.jpg");
      formdata.append("description", desc);
      formdata.append("id", row.product.id);



      const response = await fetch(baseurl + "dashboard/update-product", {

        body: formdata,
        method: "POST",

        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },

      });
      const json = await response.json();
      hideLoader()
      successNotify()

      console.log(json);
      // navigateTo("/admin/Products");
    } catch (error) {
      hideLoader()
      console.error(error + "kjjjjjjj");
    }


  };

  const initData = async () => {
    arr.slice();
    if (row != null) {

      setName(row.product.name);
      setDesc(row.product.description);


      setImages(row.product.images);

    }

  }

  useEffect(() => {

    initData()

  }, []);

  return <div>
    {loader}
    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
      <div className="breadcrumb-title pe-3">المنتجات</div>
      <div className="ps-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 p-0">
            <li className="breadcrumb-item">
              <a href="javascript:;">
                <i className="bx bx-home-alt" />
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">تعديل المنتج</li>
          </ol>
        </nav>
      </div>
    </div>
    {/*end breadcrumb*/}
    <hr />
    <div className="col-xl-12 mx-auto">
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <h6 className="mb-0 text-uppercase">معلومات المنتج</h6>
            <hr />
            <form onSubmit={postForm} encType="multipart/form-data" >
              <div asp-validation-summary="ModelOnly" className="text-danger" />
              <div className="row g-3">

                <div className="col-6">
                  <label className="form-label">اسم المنتج   </label>
                  <input onInvalid={onInputInvalid}
                    onInput={OnInput} required autoComplete="off"
                    onChange={(value) => setName(value.target.value)}
                    defaultValue={row.product.name} name="Name"
                    type="text" className="form-control" />
                  <span asp-validation-for="Name" className="text-danger" />
                </div>

                <div className="col-6">
                  <label className="form-label">  الوصف </label>
                  <input onInvalid={onInputInvalid}
                    onChange={(value) => setDesc(value.target.value)}
                    onInput={OnInput} required autoComplete="off" defaultValue={row.product.description} name="Description" type="text" className="form-control" />
                  <span asp-validation-for="Description" className="text-danger" />
                </div>

                {/* <div className="col-6">
                  <label className="form-label">السعر </label>
                  <input autoComplete="off" defaultValue={row.product.price} name="Price" type="text" className="form-control" />
                </div>
                <div className="col-6">
                  <label className="form-label">الخصم </label>
                  <input autoComplete="off" defaultValue={row.product.discount} name="Discount" type="text" className="form-control" />
                </div> */}


                {/* images */}
                <div className="col-12">
                  <label htmlFor="ImageFile" className="form-label">
                    صور للمتجر{" "}
                    <span className="text-success">
                      حجم الصورة ( 1200x600 )
                    </span>
                  </label>
                  <input

                    multiple="multiple"
                    // onInvalid={onInputInvalid}
                    onInput={OnInput}
                    onChange={handleSliderImages}
                    defaultValue={images}
                    // name="images"
                    className="form-control form-control-lg"
                    id="formFileLg"
                    type="file"
                  />
                  <span
                    asp-validation-for="ImageFile"
                    className="text-danger"
                  />
                </div>
                <div>
                  {files != null
                    ? files.map((item, i) => (
                      <div key={i} className="col">
                        <div className="image-style">
                          <button
                            type="button"
                            onClick={() => handleRemove(item)}
                            class="btn-close btn-close"
                            aria-label="Close"
                          ></button>
                          <img src={URL.createObjectURL(item)} alt="" />
                        </div>
                      </div>
                    ))
                    : row.product.images.split("#").map((item, i) => (
                      <div key={i} className="col">
                        <div className="image-style">
                          <img src={baseurl + "images/" + item} alt="" />
                        </div>
                      </div>
                    ))}
                </div>


                <div className="col-12">
                  <div className="d-grid">
                    <input onInvalid={onInputInvalid}
                      onInput={OnInput} required type="submit" value="حفظ" className="btn btn-primary" />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

}