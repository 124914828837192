

import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { baseurl, formatDate, OnInput, onInputInvalid, orderStatuses, statues, uploadImage } from "../../constants";
import Lable from "../../Components/Lable";
import RowDetails from '../../Components/RowDetails';

export default function OrderDetails() {

  const location = useLocation();
  const row = location.state.row;

  return <div>
    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
      <div className="breadcrumb-title pe-3">الطلبات</div>
      <div className="ps-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 p-0">
            <li className="breadcrumb-item">
              <a href="javascript:;">
                <i className="bx bx-home-alt" />
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">تفاصيل الطلب </li>
          </ol>
        </nav>
      </div>
    </div>
    {/*end breadcrumb*/}
    <hr />
    <div className="col-xl-12 mx-auto">
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <h6 className="mb-0 text-uppercase">معلومات الطلب </h6>
            <hr />
            <div asp-validation-summary="ModelOnly" className="text-danger" />
            <div className="row g-3">

              {/* order Details  */}
              <Lable text="تفاصيل الطلب" />
              <RowDetails name="رقم الطلب " value={row.order.id} />
              <RowDetails name="سعر الطلب" value={row.order.totalCost} />
              <RowDetails name="نوع الدفع" value={row.order.payment === 0 ? "كاش" : "اونلاين"} />
              <RowDetails name="وقت الطلب" value={formatDate(row.order.createdAt)} />
              <RowDetails name="حالة الطلب" value={orderStatuses[row.order.status]} />
              <RowDetails name="وقت الالغاء" value={row.order.status === 4 ? formatDate(row.order.updateOrder) : null} />
              <RowDetails name="وقت التسليم" value={row.order.status === 3 ? formatDate(row.order.updateOrder) : null} />
              <RowDetails name="تم الالغاء من " value={row.order.status === 4 ? row.order.roleCancle === "user" ? "المستخدم" : "المتجر" : null} />

              {/* update order status */}

              {/* <div className="col-6 my-2">
              <label className="form-label">{"تعديل حالة الطلب"} </label>
                <select

                  required
                  // name="ServiceId"
                  onChange={async (value) => {
                    //! update order status
                    console.log(value.target.value + "wefwwvefd");


                  }}
                  id="status-id"
                  className="form-select"
                >
                  <option value={""}>تعديل حالة الطلب </option>
                  {statues.map((row) => (
                    <option key={row.id} value={row.id}>
                      {row.name}
                    </option>
                  ))}
                </select>

              </div> */}




              {/* user Details  */}
              <Lable text="تفاصيل المستخدم" />
            {row.userDetail && <>
              <RowDetails name="الاسم " value={row.userDetail!==null ?
            row.userDetail.fullName : "غير مسجل" } />
              <RowDetails name="رقم الهاتف" value={row.userDetail.userName} />
              <RowDetails name="العنوان" value={row.userDetail.Address} />
              <RowDetails name="القرية" value={row.userDetail.city} />

            </>}
              {/* Provider Details  */}
              <Lable text="تفاصيل المتجر" />

              <RowDetails name="الاسم " value={row.provider.title ?? "غير مسجل"} />
              <RowDetails name="رقم الهاتف" value={row.provider.phone} />

              <RowDetails name="العنوان" value={row.provider.addressName} />

              <RowDetails name="البريد الالكتروني" value={row.provider.email} />



            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


}