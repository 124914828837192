import { Link } from "react-router-dom";
import { formatDate } from "../constants";

const TableOrder = ({data}) => {
  var orderStatuses = ["في انتظار التأكيد", "قيد الدفع", "جارى التجهيز", "تم التسليم", "تم الغاء الطلب"];
  return (<table
    id="DataTable"
    className="table table-striped table-bordered dataTable"
    role="grid"
    aria-describedby="example2_info"
  >
    <thead>
      <tr role="row">
        <th
          className="sorting_asc"
          tabIndex={0}
          aria-controls="example2"
          rowSpan={1}
          colSpan={1}
          aria-sort="ascending"
          aria-label="Name: activate to sort column descending"
          style={{ width: "50.828px" }}
        >
          <span className="mx-3">م</span>
        </th>
        <th
          className="sorting_asc"
          tabIndex={0}
          aria-controls="example2"
          rowSpan={1}
          colSpan={1}
          aria-sort="ascending"
          aria-label="Name: activate to sort column descending"
          style={{ width: "100.828px" }}
        >
          اسم المتجر{" "}
        </th>

        <th
          className="sorting"
          tabIndex={0}
          aria-controls="example2"
          rowSpan={1}
          colSpan={1}
          aria-label="Position: activate to sort column ascending"
          style={{ width: "80.891px" }}
        >
          تاريخ الطلب{" "}
        </th>
        <th
          className="sorting"
          tabIndex={0}
          aria-controls="example2"
          rowSpan={1}
          colSpan={1}
          aria-label="Position: activate to sort column ascending"
          style={{ width: "50.891px" }}
        >
          سعر الطلب
        </th>
        <th
          className="sorting"
          tabIndex={0}
          aria-controls="example2"
          rowSpan={1}
          colSpan={1}
          aria-label="Office: activate to sort column ascending"
          style={{ width: "50.312px" }}
        >
          حالة الطلب
        </th>

        {/* <th
            className="sorting"
            tabIndex={0}
            aria-controls="example2"
            rowSpan={1}
            colSpan={1}
            aria-label="Office: activate to sort column ascending"
            style={{ width: "60.312px" }}
          >
            اسم العميل
          </th>
   */}
        <th
          className="sorting"
          tabIndex={0}
          aria-controls="example2"
          rowSpan={1}
          colSpan={1}
          aria-label="Office: activate to sort column ascending"
          style={{ width: "80.312px" }}
        >
          رقم الهاتف
        </th>

        <th
          className="sorting"
          tabIndex={0}
          aria-controls="example2"
          rowSpan={1}
          colSpan={1}
          aria-label="Office: activate to sort column ascending"
          style={{ width: "60.312px" }}
        >
          تفاصيل
        </th>
      </tr>
    </thead>
    <tbody>
      {data &&
        data.items.map((item, index) => (
          <tr role="row" className="odd">
            <td>
              <span className="mx-3">{item.order.id}</span>
            </td>
            <td>{item.provider.title}</td>

            <td>{formatDate(item.order.createdAt)}</td>
            <td>{item.order.totalCost}</td>
            <td>{orderStatuses[item.order.status]}</td>

            {/* <td>{item.userDetail != null ? item.userDetail.fullName : "لا يوجد"}</td> */}


            <td>{item.userDetail != null ? item.userDetail.userName : "لا يوجد"}</td>

            <td>
              <Link state={{
                row: item,
              }} to={"/admin/order/detail"}>
                <a type="button" asp-action="Details" className="btn btn-primary m-1 text-center"><i className="far fa-eye" /></a>
              </Link>
            </td>

          </tr>
        ))}
    </tbody>
  </table>);
};
export default TableOrder;