import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl } from "../../constants";
import Switch from "@mui/material/Switch";
import { useLocation, useNavigate } from "react-router-dom";
import Orders from "./components/Orders";
var count;
var search = "";
var source = [];

const ordersStatus = [
  "في انتظار التأكيد",
  "جارى التجهيز",
  "تم التسليم",
  "تم الالغاء",
];
export default function OrdersByProvider() {
  const [data, setData] = useState();
  const [pagination, setPagination] = useState({});
  const [loader, showLoader, hideLoader] = useLoader();
  const location = useLocation();
  const row =  location.state?.row || 'لا توجد بيانات متوفرة';
  const [search, setSearch] = useState(0);
  const configurePagination = (recordCount, index ,providerId) => {
    if (index < 1 || index > count) return;

    let pagination = {};
    let pageSize = 1;
    const total = count / pageSize;
    var totalPage = parseInt(total);
    if (total > totalPage) {
      totalPage = totalPage + 1;
    }

    pagination["totalPage"] = totalPage;

    pagination["pageNo"] = index;

    const pageNumbers = [];
    for (let i = 1; i <= totalPage; i++) {
      if (i - index <= 5 || i === totalPage - 1) pageNumbers.push(i);
    }
    pagination["paginationItems"] = pageNumbers;

    setPagination(pagination);
    console.log("paginationItems");
    console.log(pagination["paginationItems"]);

    getData(index, search,providerId);
  };

  const getData = async (pageNo = 1, textSearch,providerId) => {
    console.log(row.provider.id + "stttatstter");
    showLoader();
    const formdata = new FormData();
    formdata.append("page", pageNo);
    formdata.append("providerId", providerId);
    formdata.append("textSearch", search);
    try {
      const response = await fetch(
        baseurl + "dashboard/get-orders-providerId",
        {
          method: "POST",
          // headers: {
          //   Authorization: "Bearer " + localStorage.getItem("token"),
          // },

          body: formdata,
        }
      );
      const json = await response.json();
      hideLoader();
      source = json;
      count = json.totalPages;
      console.log(json);
      setData(json);
      return json;
    } catch (error) {
      hideLoader();
      console.error(error + "kjjjjjjj");
    }
  };

  //** update provider */

  const initData = async () => {
    getData(1, search,row.provider.id);
    configurePagination(count, 1,row.provider.id);
  };

  useEffect(() => {
 
    if (!data) {
      initData();
    }
  }, []);

  return (
    <div>
      {loader}
      <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
        <div className="breadcrumb-title pe-3">المزودين</div>
        <div className="ps-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0 p-0">
              <li className="breadcrumb-item">
                <a href="javascript:;">
                  <i className="bx bx-home-alt" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                التفاصيل
                {/* {row.provider.title} */}
              </li>
            </ol>
          </nav>
        </div>
        {/* <div className="ms-auto">
                  <Link to={"/admin/field/add"}>
  
                      <div className="btn-group">
                          <a className="btn btn-success">إضافة +</a>
                      </div>
                  </Link>
              </div> */}
      </div>
      {/*end breadcrumb*/}
      <hr />
      {data && (
        <div class="row row-cols-1 text-white row-cols-lg-2 row-cols-xl-5">
          <div class="col">
            <div class="card rounded-4  bg-primary">
              <div class="card-body ">
                <div class="d-flex align-items-center">
                  <div class="">
                    <p class="mb-1">المنتجات</p>
                    <h4 class="mb-0">{data.counters.countProducts}</h4>
                    <p class="mb-0 mt-2 font-13">
                      <i class="bi bi-arrow-up"></i>
                      <span>عدد المنتجات : {data.counters.countProducts}</span>
                    </p>
                  </div>
                  <div class="ms-auto widget-icon bg-white text-primary">
                    <i class="bi bi-grid-fill"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* all orders */}
          <div class="col">
            <div class="card bg-danger rounded-4">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="">
                    <p class="mb-1">مجموع الطلبات</p>
                    <h4 class="mb-0">{data.counters.allOrders}</h4>
                    <p class="mb-0 mt-2 font-13">
                      <i class="bi bi-arrow-up"></i>
                      <span>مجموع الطلبات : {data.counters.allOrders}</span>
                    </p>
                  </div>
                  <div class="ms-auto widget-icon bg-white text-danger">
                    <i class="bi bi-basket2-fill"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end */}
          {/*  orders today*/}
          <div class="col">
            <div class="card bg-success rounded-4">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="">
                    <p class="mb-1">طلبات اليوم</p>
                    <h4 class="mb-0">{data.counters.ordersToday}</h4>
                    <p class="mb-0 mt-2 font-13">
                      <i class="bi bi-arrow-up"></i>
                      <span>عدد طلبات اليوم : {data.counters.ordersToday}</span>
                    </p>
                  </div>
                  <div class="ms-auto widget-icon bg-white text-success">
                    <i class="bi bi-basket2-fill"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end */}
          <div class="col">
            <div class="card bg-orange rounded-4">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="">
                    <p class="mb-1">طلبات الاسبوع</p>
                    <h4 class="mb-0">{data.counters.ordersLastWeak}</h4>
                    <p class="mb-0 mt-2 font-13">
                      <i class="bi bi-arrow-up"></i>
                      <span>
                        {" "}
                        طلبات الاسبوع : {data.counters.ordersLastWeak}{" "}
                      </span>
                    </p>
                  </div>
                  <div class="ms-auto widget-icon bg-white text-orange">
                    <i class="bi bi-award-fill"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card bg-secondary rounded-4">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="">
                    <p class="mb-1">طلبات الشهر</p>

                    <h4 class="mb-0">{data.counters.ordersLastMonth}</h4>
                    <p class="mb-0 mt-2 font-13">
                      <i class="bi bi-arrow-up"></i>
                      <span>
                        عدد طلبات الشهر : {data.counters.ordersLastMonth}
                      </span>
                    </p>
                  </div>
                  <div class="ms-auto widget-icon bg-white text-secondary">
                    <i class="bx bxs-package"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

    


      {/* orders */}
      <h3 className="home-order-header"> الطلبات</h3>

       {/* search  */}
      {/*end of col*/}
      <div className="row justify-content-start">
        <div className="col-12 col-md-4">
          <div className="card-body row no-gutters align-items-start">
            {/*end of col*/}
            <div className="col">
              <input
                autoComplete="off"
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                className="form-control form-control form-control-borderless"
                type="search"
                placeholder="بحث في القائمة"
              />
            </div>
            {/*end of col*/}
            <div className="col-auto">
              <button
                onClick={(e) => getData(1, search,row.provider.id)}
                className="btn btn btn-primary"
                type="submit"
              >
                بحث
              </button>
            </div>
            {/*end of col*/}
          </div>
        </div>
        {/*end of col*/}
      </div>
      {/*end of col*/}
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <div
              id="example2_wrapper"
              className="dataTables_wrapper dt-bootstrap5"
            >
              <div className="row">
                <div className="col-sm-12">
                  <p></p>
                  <div className="row justify-content-start">
                    {/*end of col*/}
                  </div>
                  <p />

                  <Orders data={data } provider={row.provider} />
                  <br />

                  {data && (
                    <nav aria-label="Page navigation example">
                      <ul class="pagination">
                        <li
                          onClick={(e) =>
                            configurePagination(
                              pagination["totalPage"],
                              pagination["pageNo"] - 1,
                              data.data.items[0].order.id
                            )
                          }
                          class="page-item"
                        >
                          <a class="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">&laquo;</span>
                            <span class="sr-only">Previous</span>
                          </a>
                        </li>

                        {pagination["paginationItems"]?.map(
                          (_, i) =>
                            pagination["pageNo"] &&
                            (pagination["pageNo"] - i < 5 &&
                            i - pagination["pageNo"] < 5 ? (
                              <li
                                onClick={(e) =>
                                  configurePagination(
                                    pagination["totalPage"],
                                    i + 1,
                                    data.data.items[0].order.id
                                  )
                                }
                                class={
                                  i + 1 != pagination["pageNo"]
                                    ? "page-item"
                                    : "page-item active"
                                }
                              >
                                <a class="page-link" href="#">
                                  {i + 1}
                                </a>
                              </li>
                            ) : (
                              <></>
                            ))
                        )}

                        <li
                          onClick={(e) =>
                            configurePagination(
                              pagination["totalPage"],
                              pagination["pageNo"] + 1
                            )
                          }
                          class="page-item"
                        >
                          <a class="page-link" href="#" aria-label="Next">
                            <span aria-hidden="true">&raquo;</span>
                            <span class="sr-only">Next</span>
                          </a>
                        </li>
                      </ul>
                    </nav>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
