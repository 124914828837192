import React from 'react'

const RowDetails = ({ name, value }) => {
    return (
        <>
            {value && <div className="col-6 my-2">
                <label className="form-label">{name} </label>
                <input disabled defaultValue={value}
                    required autoComplete="off" name="Name" type="text" className="form-control" />
                <span asp-validation-for="Name" className="text-danger" />
            </div>}




        </>
    )
}

export default RowDetails
