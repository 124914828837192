import { useEffect, useState } from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { baseurl } from "../../constants";
import Typography from '@mui/material/Typography';
import useLoader from "../../Components/loader/useLoader";

import PaginatedTable from "../../Components/PaginatedTable";
import TableOrder from "../../Components/OrdersTable";
import { Await } from "react-router-dom";

var count;

var source = [];
var orderStatuses = ["في انتظار التأكيد", "جارى التجهيز", "تم التسليم", "تم الغاء الطلب"];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

export default function OrdersTabs() {
  const [data, setData] = useState();
  const [pagination, setPagination] = useState({});
  const [loader, showLoader, hideLoader] = useLoader();
  const [type, setType] = useState(0);

  const [search, setSearch] = useState(0);


  const handleChange = async (event, newValue) => {
    await getData(1, newValue, 0);
    setType(newValue);

  };


  const rowIndex = (index) => {
    return (pagination["pageNo"] - 1) * 8 + (index + 1);
  };

  const configurePagination = (recordCount, index) => {
    if (index < 1 || index > count) return;

    let pagination = {};
    let pageSize = 1;
    const total = count / pageSize;
    var totalPage = parseInt(total);
    if (total > totalPage) {
      totalPage = totalPage + 1;
    }

    pagination["totalPage"] = totalPage;

    pagination["pageNo"] = index;

    const pageNumbers = [];
    for (let i = 1; i <= totalPage; i++) {
      if (i - index <= 5 || i === totalPage - 1) pageNumbers.push(i);
    }
    pagination["paginationItems"] = pageNumbers;

    setPagination(pagination);
    console.log("paginationItems");
    console.log(pagination["paginationItems"]);

    getData(index, type, search);
  };



  //** get data  */
  const getData = async (pageNo = 1, type, textSearch) => {
    showLoader();
    const formdata = new FormData();
    formdata.append("page", pageNo);
    formdata.append("type", type);
    formdata.append("textSearch", textSearch);

    try {
      const response = await fetch(baseurl + "dashboard/get-All-orders", {
        method: "POST",
        // headers: {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // },

        body: formdata,
      });
      const json = await response.json();
      hideLoader();
      source = json;
      count = json.totalPages;
      console.log(json);
      setData(json);
      return json;
    } catch (error) {
      hideLoader();
      console.error(error + "kjjjjjjj");
    }
  };

  const initData = async () => {
    // if (!Products) {
    //   await getProducts()
    // }

    getData(1, search);
    configurePagination(count, 1);
  };

  useEffect(() => {
    if (!data) {
      initData();
    }
  }, []);


  const [rowsPerPage, setRowsPerPage] = useState(1);
  const [page, setPage] = useState(0);

  return (
    <Box sx={{ width: '100%' }}>

      {/* search  */}
      {/*end of col*/}
      <div className="row justify-content-start">
        <div className="col-12 col-md-4">
          <div className="card-body row no-gutters align-items-start">
            {/*end of col*/}
            <div className="col">
              <input
                autoComplete="off"
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                className="form-control form-control form-control-borderless"
                type="search"
                placeholder="بحث في القائمة"
              />
            </div>
            {/*end of col*/}
            <div className="col-auto">
              <button
                onClick={(e) => getData(1, 0, search)}
                className="btn btn btn-primary"
                type="submit"
              >
                بحث
              </button>
            </div>
            {/*end of col*/}
          </div>
        </div>
        {/*end of col*/}
      </div>
      {/*end of col*/}


      <Tabs value={type} onChange={handleChange} aria-label="order tabs">
        <Tab label="كل الطلبات" {...a11yProps(0)} />
        <Tab label="طلبات اليوم" {...a11yProps(1)} />
        <Tab label="طلبات الأسبوع" {...a11yProps(2)} />
        <Tab label="طلبات الشهر" {...a11yProps(3)} />
      </Tabs>

      <TabPanel value={type} index={0}>
        <div>
          {loader}
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">


            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <div
                    id="example2_wrapper"
                    className="dataTables_wrapper dt-bootstrap5"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        {/* count  */}
                        {data && <div className="order-count">
                          عدد الطلبات:  {data.allOrders ?? 0}
                        </div>}



                        <br />
                        <TableOrder data={data} />
                        <br />
                        {data && (
                          <nav aria-label="Page navigation example">
                            <ul class="pagination">
                              <li
                                onClick={(e) =>
                                  configurePagination(
                                    pagination["totalPage"],
                                    pagination["pageNo"] - 1
                                  )
                                }
                                class="page-item"
                              >
                                <a class="page-link" href="#" aria-label="Previous">
                                  <span aria-hidden="true">&laquo;</span>
                                  <span class="sr-only">Previous</span>
                                </a>
                              </li>

                              {pagination["paginationItems"]?.map(
                                (_, i) =>
                                  pagination["pageNo"] &&
                                  (pagination["pageNo"] - i < 5 &&
                                    i - pagination["pageNo"] < 5 ? (
                                    <li
                                      onClick={(e) =>
                                        configurePagination(
                                          pagination["totalPage"],
                                          i + 1
                                        )
                                      }
                                      class={
                                        i + 1 != pagination["pageNo"]
                                          ? "page-item"
                                          : "page-item active"
                                      }
                                    >
                                      <a class="page-link" href="#">
                                        {i + 1}
                                      </a>
                                    </li>
                                  ) : (
                                    <></>
                                  ))
                              )}

                              <li
                                onClick={(e) =>
                                  configurePagination(
                                    pagination["totalPage"],
                                    pagination["pageNo"] + 1
                                  )
                                }
                                class="page-item"
                              >
                                <a class="page-link" href="#" aria-label="Next">
                                  <span aria-hidden="true">&raquo;</span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </li>
                            </ul>
                          </nav>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={type} index={1}>
        <div>
          {loader}
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">


            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <div
                    id="example2_wrapper"
                    className="dataTables_wrapper dt-bootstrap5"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        {data && <div className="order-count">
                          عدد الطلبات:  {data.allOrders ?? 0}
                        </div>}

                        <br></br>
                        {data && <TableOrder data={data} />}
                        <br />

                        {data && (
                          <nav aria-label="Page navigation example">
                            <ul class="pagination">
                              <li
                                onClick={(e) =>
                                  configurePagination(
                                    pagination["totalPage"],
                                    pagination["pageNo"] - 1
                                  )
                                }
                                class="page-item"
                              >
                                <a class="page-link" href="#" aria-label="Previous">
                                  <span aria-hidden="true">&laquo;</span>
                                  <span class="sr-only">Previous</span>
                                </a>
                              </li>

                              {pagination["paginationItems"]?.map(
                                (_, i) =>
                                  pagination["pageNo"] &&
                                  (pagination["pageNo"] - i < 5 &&
                                    i - pagination["pageNo"] < 5 ? (
                                    <li
                                      onClick={(e) =>
                                        configurePagination(
                                          pagination["totalPage"],
                                          i + 1
                                        )
                                      }
                                      class={
                                        i + 1 != pagination["pageNo"]
                                          ? "page-item"
                                          : "page-item active"
                                      }
                                    >
                                      <a class="page-link" href="#">
                                        {i + 1}
                                      </a>
                                    </li>
                                  ) : (
                                    <></>
                                  ))
                              )}

                              <li
                                onClick={(e) =>
                                  configurePagination(
                                    pagination["totalPage"],
                                    pagination["pageNo"] + 1
                                  )
                                }
                                class="page-item"
                              >
                                <a class="page-link" href="#" aria-label="Next">
                                  <span aria-hidden="true">&raquo;</span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </li>
                            </ul>
                          </nav>
                        )}




                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={type} index={2}>
        <div>
          {loader}
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">


            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <div
                    id="example2_wrapper"
                    className="dataTables_wrapper dt-bootstrap5"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        {data && <div className="order-count">
                          عدد الطلبات:  {data.allOrders ?? 0}
                        </div>}
                        <br />
                        <TableOrder data={data} />
                        <br />

                        {data && (
                          <nav aria-label="Page navigation example">
                            <ul class="pagination">
                              <li
                                onClick={(e) =>
                                  configurePagination(
                                    pagination["totalPage"],
                                    pagination["pageNo"] - 1
                                  )
                                }
                                class="page-item"
                              >
                                <a class="page-link" href="#" aria-label="Previous">
                                  <span aria-hidden="true">&laquo;</span>
                                  <span class="sr-only">Previous</span>
                                </a>
                              </li>

                              {pagination["paginationItems"]?.map(
                                (_, i) =>
                                  pagination["pageNo"] &&
                                  (pagination["pageNo"] - i < 5 &&
                                    i - pagination["pageNo"] < 5 ? (
                                    <li
                                      onClick={(e) =>
                                        configurePagination(
                                          pagination["totalPage"],
                                          i + 1
                                        )
                                      }
                                      class={
                                        i + 1 != pagination["pageNo"]
                                          ? "page-item"
                                          : "page-item active"
                                      }
                                    >
                                      <a class="page-link" href="#">
                                        {i + 1}
                                      </a>
                                    </li>
                                  ) : (
                                    <></>
                                  ))
                              )}

                              <li
                                onClick={(e) =>
                                  configurePagination(
                                    pagination["totalPage"],
                                    pagination["pageNo"] + 1
                                  )
                                }
                                class="page-item"
                              >
                                <a class="page-link" href="#" aria-label="Next">
                                  <span aria-hidden="true">&raquo;</span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </li>
                            </ul>
                          </nav>
                        )}




                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel value={type} index={3}>
        <div>
          {loader}
          <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">


            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <div
                    id="example2_wrapper"
                    className="dataTables_wrapper dt-bootstrap5"
                  >
                    <div className="row">
                      <div className="col-sm-12">
                        {data && <div className="order-count">
                          عدد الطلبات:  {data.allOrders ?? 0}
                        </div>}
                        <br />
                        <TableOrder data={data} />

                        <br />

                        {data && (
                          <nav aria-label="Page navigation example">
                            <ul class="pagination">
                              <li
                                onClick={(e) =>
                                  configurePagination(
                                    pagination["totalPage"],
                                    pagination["pageNo"] - 1
                                  )
                                }
                                class="page-item"
                              >
                                <a class="page-link" href="#" aria-label="Previous">
                                  <span aria-hidden="true">&laquo;</span>
                                  <span class="sr-only">Previous</span>
                                </a>
                              </li>

                              {pagination["paginationItems"]?.map(
                                (_, i) =>
                                  pagination["pageNo"] &&
                                  (pagination["pageNo"] - i < 5 &&
                                    i - pagination["pageNo"] < 5 ? (
                                    <li
                                      onClick={(e) =>
                                        configurePagination(
                                          pagination["totalPage"],
                                          i + 1
                                        )
                                      }
                                      class={
                                        i + 1 != pagination["pageNo"]
                                          ? "page-item"
                                          : "page-item active"
                                      }
                                    >
                                      <a class="page-link" href="#">
                                        {i + 1}
                                      </a>
                                    </li>
                                  ) : (
                                    <></>
                                  ))
                              )}

                              <li
                                onClick={(e) =>
                                  configurePagination(
                                    pagination["totalPage"],
                                    pagination["pageNo"] + 1
                                  )
                                }
                                class="page-item"
                              >
                                <a class="page-link" href="#" aria-label="Next">
                                  <span aria-hidden="true">&raquo;</span>
                                  <span class="sr-only">Next</span>
                                </a>
                              </li>
                            </ul>
                          </nav>
                        )}




                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </TabPanel>
    </Box>
  );

}



