import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useLoader from "../../Components/loader/useLoader";
import { baseurl, notify, successNotify, successToast, uploadImage } from "../../constants";



export default function CreateField(){

  const[errors,setErrors] = useState({})
  var [nameAr,setNameAr] = useState()
  var [nameEn,setNameEn] = useState()
  var [image,setImage] = useState()
  const [loader, showLoader, hideLoader] = useLoader(); 

  
  const handleNewImage = event => {
    image = event.target.files[0]
  
  }


   const handleValidation=()=>{
    let errors = [];
    let formIsValid = true;
            
        if(!nameAr){
          formIsValid = false;

          errors["nameAr"]="هذا الحقل مطلوب"
        }
        if(!nameEn){
          formIsValid = false;

          errors["nameEn"]="هذا الحقل مطلوب"
        }

        if(!image){
          formIsValid = false;

          errors["image"]="هذا الحقل مطلوب"
        }

        setErrors(errors)

        return formIsValid;

    }
   
    const navigateTo = useNavigate()
    const postForm = async () => {
      showLoader()

         if(handleValidation()==false)return;

         let uploadedImage =await uploadImage(image)

         console.log(uploadedImage);

        const formdata = new FormData()
        formdata.append("name",nameAr)
        formdata.append("nameEn",nameEn)
        formdata.append("image",uploadedImage)

          const response = await fetch(baseurl+"field/add", {
            method: "POST",
            headers: {

              "Authorization":"Bearer "+localStorage.getItem("token")
            },
            body:formdata
          });
          const json = await response.json();
          hideLoader()
          successNotify()
          console.log(json);
          navigateTo("/admin/fields");
       
      };




    return <div>



    <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
      <div className="breadcrumb-title pe-3">الفئات</div>
      <div className="ps-3">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 p-0">
            <li className="breadcrumb-item">
              <a href="javascript:;">
                <i className="bx bx-home-alt" />
              </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">إضافة فئة</li>
          </ol>
        </nav>
      </div>
    </div>
    {/*end breadcrumb*/}
    <hr />
    <div className="col-xl-12 mx-auto text-start">
      <div className="card">
        <div className="card-body">
          <div className="border p-3 rounded">
            <h6 className="mb-0 text-uppercase">معلومات الفئة</h6>
            <hr />
              <div asp-validation-summary="ModelOnly" className="text-danger " />
              <div className="row g-3">
                <div className="col-6">
                  <label className="form-label">الإسم باللغة العربية </label>
                  <input autoComplete="off" onChange={(e) => (nameAr=e.target.value ?? nameAr )} type="text" className="form-control" />
                 <span asp-validation-for="NameEn" className="text-danger" >{errors["nameAr"]}</span>
                </div>
                <div className="col-6">
                  <label className="form-label">الإسم باللغة الإنجليزية </label>
                  <input autoComplete="off" onChange={(e) => (nameEn=e.target.value ?? nameEn)} asp-for="NameEn" type="text" className="form-control" />
                  <span asp-validation-for="NameEn" className="text-danger" >{errors["nameEn"]}</span>
                </div>
                <div className="col-6">
                  <label htmlFor="ImageFile" className="form-label"> صورة للفئة <span className="text-success">حجم الصورة (   260x260 )</span></label>
                  <input onChange={handleNewImage} asp-for="ImageFile" className="form-control form-control-lg" id="formFileLg" type="file" />
                  <span asp-validation-for="NameEn" className="text-danger" >{errors["image"]}</span>
                </div>
                <div className="col-12" onClick={postForm}>
                  <div className="d-grid">
                    <input type="submit" value="حفظ" className="btn btn-primary" />
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
}